import request from '@/utils/request'


// 查询小区锁门禁卡 列表
export function listLockCard(query) {
  return request({
    url: '/user/village-lock-cards/list',
    method: 'get',
    params: query
  })
}

// 查询小区锁门禁卡 分页
export function pageLockCard(query) {
  return request({
    url: '/user/village-lock-cards/page',
    method: 'get',
    params: query
  })
}

// 查询小区锁门禁卡 详细
export function getLockCard(data) {
  return request({
    url: '/user/village-lock-cards/detail',
    method: 'get',
    params: data
  })
}

// 新增小区锁门禁卡
export function addLockCard(data) {
  return request({
    url: '/user/village-lock-cards/add',
    method: 'post',
    data: data
  })
}

// 修改小区锁门禁卡
export function updateLockCard(data) {
  return request({
    url: '/user/village-lock-cards/edit',
    method: 'post',
    data: data
  })
}

// 删除小区锁门禁卡
export function delLockCard(data) {
  return request({
    url: '/user/village-lock-cards/delete',
    method: 'post',
    data: data
  })
}
